.navbar-hr {
  border-color: black;
  border-width: 4px;
  margin-top: 0;
}

.navbar-icons .navbar-link { padding: 5px; }
.navbar-links .navbar-link { padding: 5px 10px; }

.navbar-icons, .navbar-links {
  .form-inline {
    button {
      font-size: 15px;
      text-transform: uppercase;
    }
    input {
      border: none;
      font-size: 15px;
      height: auto;
      padding: 3px;
    }

    @include media-breakpoint-down(sm) {
      button {
        display: block;
        margin: 5px 0;
        width: 100%;
      }
      input {
        width: 100%;
      }
    }

    @include media-breakpoint-up(md) {
      height: 34px;
    }
  }
  .navbar-link {
    color: black;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;

    &.active, &:focus {
      color: $blue;
    }

    @include media-breakpoint-up(lg) {
      &:first-of-type {
        padding-left: 0;
      }
    }
  }

  button:not(.btn) {
    background: none;
    border: none;
    cursor: pointer;
  }
}

.navbar-links-responsive {
  > button {
    color: $black;
    position: absolute;
    right: 20px;
  }
  .in.navbar-collapse {
    background-color: $black;
    bottom: 0;
    position: fixed;
    opacity: 0.8;
    top: 0;
    right: 0;
    z-index: 10;
    .navbar-link {
      padding: 3px 5px;
      &:not(.active) { color: $white; }
    }
  }
  &.navbar-open {
    > button { display: none; }
  }
}

.navbar-side-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-up(lg) {
    min-height: 150px;
  }

  @include media-breakpoint-down(lg) {
    .text-accent {
      margin-top: 10px;
    }
  }
}

.public-navbar {
  background: white;
  margin-top: 20px;
  top: 0;
  z-index: 10;

  .text-accent {
    font-size: 18px;
    font-weight: bold;
  }

  @include media-breakpoint-down(md) {
    margin-top: 0;
    position: initial;
  }
}

// Bootstrap Based
.navbar-brand {
  font-size: 1.5em;
}

.navbar-center {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 5px;
  transform: translate(-50%, 0%);
}

.navbar-condensed-inline-sm {
  @include media-breakpoint-down(sm) {
    display: block;
    li {
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.navbar-nav button {
  background: none;
  border: none;
  cursor: pointer;
}

.navbar-dark .navbar-nav .nav-link {
  a, button {
    color: $navbar-dark-color;

    @include hover-focus() {
      color: $navbar-dark-hover-color;
    }

    &.disabled {
      color: $navbar-dark-disabled-color;
    }
  }
}

.nav-item a:hover {
  text-decoration: none;
}
