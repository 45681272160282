html, body, #app, .app {
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.page {
  flex-grow: 1;
  position: relative;
}

.page, .public-navbar {
  margin-left: 20px;
  margin-right: 20px;
}

.page-heading {
  font-size: 3rem;
  text-transform: uppercase;
  padding-bottom: 20px;

  @include media-breakpoint-up(lg) {
    font-size: 68px;
    font-weight: normal;
  }
}

.sidebar {
  .store-links {
    a { display: block; margin: 10px 0;}
    img { max-width: 100%; }
  }
  .attachment__caption {
    display: none;
  }
  .trix-content :not(.attachment-gallery) > action-text-attachment {
    margin: 0;
    text-align: left;
  }
}

.sidebar-link {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.75px;
  text-decoration: underline;
  text-transform: uppercase;
}
