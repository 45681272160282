.borderless-form {
  input {
    border-left: 0;
    border-right: 0;
    border-top: 0;
  }
}

.btn-primary {
  @include hover() {
    color: color-yiq($primary);
    @include gradient-bg($primary);
    border-color: $primary;
  }

  &:focus, &.focus {
    color: $primary;
    @include gradient-bg($gray);
    border-color: $gray;
    box-shadow: 0;
  }
}

.extra-small {
  @include font-size(60%);
  font-weight: $font-weight-normal;
}

.letter-spacing-wide { letter-spacing: 0.2em; }
.line-height-sm { line-height: 1.25; }

.notifications {
  left: 50%;
  position: fixed;
  text-align: center;
  top: 5px;
  transform: translate(-50%, 0%);
  width: 350px;
  z-index: 15;
}

.share-buttons {
  .share-link {
    background: $blue;
    border-radius: 5px;
    color: $white;
    display: inline-block;
    vertical-align: top;
    .fa-envelope, .fa-link {
      height: 24px;
      padding: 5px;
      width: 24px;
    }
  }
  a, button {
    display: inline-block;
    margin: 5px;

    * { display: block; }
  }
}

.share-space {
  min-height: 40px;
}

.table {
  border: 1px solid $table-dark-bg;
  i {
    color: $table-dark-bg;
  }
}

.text-accent {
  color: #4E73D2;
}

.text-black {
  color: $black;
}

.img-medium {
  max-height: 300px;
}

.modal-body {
  overflow-y: auto;
}

.modal-content {
  max-height: 90vh;
}

.modal-dialog {
  overflow-y: initial !important;
}

.preserve-whitespace {
  white-space: pre-wrap;
}

audio[controls] {
  max-width: 100%;
  min-height: 60px;
}

a, button {
  &:active, &:focus, &:hover { color: $dark; }
  &.fa:hover { text-decoration: none; }
}

button {
  background: none;
  border: none;
  color: $link-color;
  padding: 0;

  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

h1, h2, h3, h4, h5, h6 { color: $black; }
