$black: #000000;
$blue: #062F89;
$gray: #8D8E8D;
$body-color: $gray;
$input-border-color: $black;

$font-family-sans-serif: "Lato";
$font-size-base: 1.3rem;
$h-font-modifier: 0.8;
$h1-font-size: $h-font-modifier * $font-size-base * 2.5 !default;
$h2-font-size: $h-font-modifier * $font-size-base * 2 !default;
$h3-font-size: $h-font-modifier * $font-size-base * 1.75 !default;
$h4-font-size: $h-font-modifier * $font-size-base * 1.5 !default;
$h5-font-size: $h-font-modifier * $font-size-base * 1.25 !default;
$h6-font-size: $h-font-modifier * $font-size-base !default;
$small-font-size: 65% !default;
$toast-background-color: white;

@import "bootstrap-scss";
@import "./style/action-text.scss";
@import "./style/default.scss";
@import "./style/episode.scss";
@import "./style/layout.scss";
@import "./style/navbar.scss";
